class BannerIT extends BATComponent {
	/* Lifecycle event callbacks */
	beforeLoad() {
		// console.log('MinicartTemplateVelo before load');
		
		
	}

	afterLoad() {
		// console.log('MinicartTemplateVelo after load');
		this.$el = $(this);


		$.each(this.$el.find('button[data-parameters]'), (index, button) => {
			const buttonType = $(button).data('cta-type');

			switch (buttonType) {
				case 'modal':
					// modal button click
					$(button).click(() => {
						const targetId = $(button)
							.data('parameters')
							.replace('#', '');
						$(`#${targetId}`)
							.addClass('active')
							.trigger('modal:open');
					});
					break;
				default:
					break;
			}
		});
	}

	beforeUpdate() {
		// console.log('MinicartTemplateVelo before update');
	}

	afterUpdate() {
		// console.log('MinicartTemplateVelo after update');
	}

	unload() {
		// console.log('MinicartTemplateVelo after unload');
	}
}

!customElements.get('bat-card-gloitnewsletterpopup') &&
	customElements.define('bat-card-gloitnewsletterpopup', BannerIT);
